"use client";
import { doPost } from "@licommon/authOptions";
import { useCallback, useState } from "react";
import { FieldValues, UseFormSetError } from "react-hook-form";

type PostState<T> = {
  loading: boolean;
  data: T | null;
  error: any;
};

type ResponseError = {
  message: string;
  errors?: { [key: string]: string[] } | null;
};

const usePost = <V extends FieldValues, R>(
  routeName: string,
  setError?: UseFormSetError<V>,
) => {
  const [state, setState] = useState<PostState<R>>({
    loading: false,
    data: null,
    error: null,
  });

  const postData = useCallback(
    async (postData: any, options = {}, headers = {}) => {
      return new Promise<R>(async (resolve, reject) => {
        setState((prevState) => ({ ...prevState, loading: true }));
        try {
          const response = await doPost<R>(
            routeName,
            postData,
            options,
            headers,
          );
          if (response.errors) throw response;
          setState({ loading: false, data: response.data, error: null });
          resolve(response as R);
        } catch (response: any) {
          setState({ loading: false, data: null, error: response.message });
          let validationErrors = response.errors as ResponseError;
          if (setError && validationErrors) {
            Object.keys(validationErrors).forEach((name) => {
              //@ts-ignore
              setError(name, {
                type: "server",
                //@ts-ignore
                message: validationErrors[name][0],
              });
            });
          }
          reject(response);
        }
      });
    },
    [routeName, setError],
  );

  return {
    loading: state.loading,
    data: state.data,
    error: state.error,
    post: postData,
  };
};

export default usePost;
