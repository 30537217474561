"use client";

import { clsx } from "@licommon/utils/clsx";
import { getPortalType } from "@licommon/utils/portal";

type TabsProp = {
  items: string[];
  activeIndex: number;
  bordered?: boolean;
  rounded?: boolean;
  containerClassName?: string;
  itemClassName?: string;
  firstItemClassName?: string;
  lastItemClassName?: string;
  isActiveClass?: string;
  onChange?: Function;
};
const Tabs = (props: TabsProp) => {
  const {
    items,
    activeIndex,
    bordered = true,
    rounded = false,
    isActiveClass = "",
    containerClassName = "",
    itemClassName = "",
    firstItemClassName = "",
    lastItemClassName = "",
    onChange = () => {},
  } = props;
  const { isCFD } = getPortalType();

  return (
    <div className={`flex ${containerClassName}`}>
      {items.map((tab, index) => {
        const isFirst = index === 0;
        const isLast = index === items.length - 1;
        const isActive = index === activeIndex;
        return (
          <div
            className={clsx(
              "px-4 py-3 text-center min-w-[120px] flex-1 font-medium",
              isActiveClass && isActive ? isActiveClass : "",
              isActive &&
                !isActiveClass &&
                "text-green-800 bg-white border-b-0",
              !isActive && "text-gray-500 bg-gray-100 cursor-pointer",
              bordered && "border border-gray-300",
              isFirst && bordered && items.length > 2 && "border-r-0",
              isLast && bordered && "border-l-0",
              itemClassName,
              isFirst && firstItemClassName,
              isLast && lastItemClassName,
              isFirst && rounded && "rounded-l-md",
              isLast && rounded && "rounded-r-md"
            )}
            key={tab}
            onClick={() => {
              if (isActive) return;
              onChange(index);
            }}
          >
            {tab}
          </div>
        );
      })}
    </div>
  );
};
export default Tabs;
