'use client'
import Modal from '@licommon/components/Modal'
import Tabs from '@licommon/components/Tabs'
import { showError, showSuccess } from '@licommon/utils/alert'
import { getSessionToken } from '@licommon/utils/session'
import { setCookie } from 'cookies-next'
import { signIn } from 'next-auth/react'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { clsx } from '../../utils/clsx'
import { getPortalType } from '../../utils/portal'
import Button from '../Button'
import { Question } from '../Icons'
import OrComponent from '../OrComponent'
import SectionLoading from '../SectionLoading'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'

const AuthTab = ({
  type,
  initialData = {},
  redirect = '',
  mainTitle,
  showTab,
  showSubTitle,
  showSocialOptions = true,
  onSuccessReload = true,
  onSuccess = () => {},
  redirectSocialLogin,
}: {
  type: 'login' | 'register'
  initialData?: { email?: string }
  redirect?: string
  mainTitle?: string
  showTab?: boolean
  showSubTitle?: boolean
  showSocialOptions?: boolean
  onSuccess?: () => void
  onSuccessReload?: boolean
  redirectSocialLogin?: string
}) => {
  const [tab, setTab] = useState(type === 'login' ? 0 : 1)
  const buttonText = tab === 0 ? 'Sign in with' : 'Create account with'
  const router = useRouter()
  const { isCFD } = getPortalType()
  const [isLinkAccount, setIsLinkAccount] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      const url = decodeURIComponent(window.location.href)
      const parsedUrl = new URL(url)
      const queryParams = parsedUrl.searchParams
      // @ts-ignore
      const error = queryParams.get('error')
      // @ts-ignore
      const linkable = queryParams.get('linkable')
      if (linkable && linkable != 'undefined') {
        setIsLinkAccount(true)
      }
      if (error && error != 'undefined' && error.length) {
        showError(error)
      }
    }, 1000)
  }, [])
  const handleSocialAuth = async (provider: string) => {
   const sessionToken = getSessionToken()
   setCookie('authType', tab === 0 ? 'login' : 'register')
   setCookie('sessionToken', sessionToken)
    signIn(provider, {
      callbackUrl: `
      ${
        redirectSocialLogin
          ? window.location.origin + redirectSocialLogin
          : ` ${window.location.origin}/?auth=success`
      }
      `,
    })
  }

  const linkAccount = () => async () => {
    setLoading(true)
    const url = decodeURIComponent(window.location.href)
    const parsedUrl = new URL(url)
    const queryParams = parsedUrl.searchParams
    // @ts-ignore
    const email = queryParams.get('email')
    // @ts-ignore
    const provider = queryParams.get('provider')
    // @ts-ignore
    const social_id = queryParams.get('social_id')
    // @ts-ignore
    const accessToken = queryParams.get('accessToken')
    // @ts-ignore
    const parsedCustomer = {
      email,
      provider,
      social_id,
      accessToken,
    }
    if (parsedCustomer) {
      signIn('token', {
        ...parsedCustomer,
        redirect: false,
      })
        .then((response) => {
         redirectSocialLogin
         ? router.replace(redirectSocialLogin)
         : router.replace('/')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
  return (
    <div className="mx-auto flex flex-col px-3">
      {mainTitle && (
        <div className="text-blue-700 text-xl md:text-3xl text-center mb-8 md:mb-14 font-medium">
          {mainTitle}
        </div>
      )}
      {loading && <SectionLoading className="fixed w-full h-full" />}
      <Modal
        title="Link Account"
        onClose={() => {
          setIsLinkAccount(false)
          router.replace(tab == 0 ? '/auth/login' : '/auth/register')
        }}
        show={isLinkAccount}
      >
        <div className="bg-white rounded-lg flex-col min-h-[200px] flex text-gray-900 justify-center items-center">
          <div className="text-primary">
            <Question size={50} />
          </div>
          <h1 className="text-2xl text-center font-medium  mt-2">
            We found an account with this email address. Would you like to link
            it to your social account ?
          </h1>
          <div className="flex justify-between mt-4 gap-5">
            <Button
              onClick={linkAccount()}
              loading={loading}
              variant="success"
              className="rounded-md justify-center"
            >
              Link
            </Button>
            <Button
              onClick={() => {
                setIsLinkAccount(false)
                router.replace(tab == 0 ? '/auth/login' : '/auth/register')
              }}
              variant="secondary"
              className="rounded-md justify-center"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <div className="w-full flex flex-col justify-center rounded-md border-gray-300 border max-w-[600px] mx-auto">
        {showTab && (
          <Tabs
            containerClassName="w-full"
            items={['Log In', 'Create Account']}
            activeIndex={tab}
            onChange={(tab: number) => {
              setTab(tab)
              if (onSuccessReload) {
                router.replace(tab == 0 ? '/auth/login' : '/auth/register')
              }
            }}
            itemClassName="border-l-0 border-t-0"
            lastItemClassName="border-r-0 rounded-tr-md"
            firstItemClassName="rounded-tl-md"
          />
        )}
        {showSubTitle && (
          <div className="pt-8 flex text-xl md:text-3xl font-medium justify-center">
            {tab == 0 ? 'Login' : 'Create an Account'}
          </div>
        )}
        <div className="w-full flex flex-col p-5">
          {showSocialOptions && (
            <>
              <SocialButton
                icon="/images/icons/icon-google.svg"
                text={`${buttonText} Google`}
                onClick={() => {
                  handleSocialAuth('google')
                }}
                className={clsx('mb-3', isCFD && 'rounded-lg')}
              />
              <SocialButton
                icon="/images/icons/icon-apple.svg"
                text={`${buttonText} Apple`}
                onClick={() => {
                  handleSocialAuth('apple')
                }}
                className={clsx('mb-3', isCFD && 'rounded-lg')}
              />
              <SocialButton
                icon="/images/icons/icon-facebook.svg"
                text={`${buttonText} Facebook`}
                onClick={() => {
                  handleSocialAuth('facebook')
                }}
                className={clsx('mb-3', isCFD && 'rounded-lg')}
              />
              <OrComponent />
            </>
          )}
          <div className={clsx(tab === 0 ? 'block' : 'hidden')}>
            <LoginForm
              initialData={initialData}
              onSuccess={() => {
                if (onSuccessReload) {
                  showSuccess("Successfully Logged In")
                  router.push(redirect ? redirect : '/')
                }
                onSuccess()
              }}
            />
          </div>
          <div className={clsx(tab === 1 ? 'block' : 'hidden')}>
            <RegisterForm
              initialData={initialData}
              onSuccess={() => {
                if (onSuccessReload) {
                  router.push(redirect ? redirect : '/')
                }
                onSuccess()
              }}
            />
          </div>
          <div className="mt-5">
            {tab === 1
              ? 'Already a member?'
              : isCFD
                ? 'New to Ceiling Fans Direct?'
                : 'New to Lighting Illusions?'}{' '}
            <span
              className={`${
                isCFD ? 'text-blue-700' : 'text-green-700'
              } underline cursor-pointer`}
              onClick={() => setTab(tab === 1 ? 0 : 1)}
            >
              {tab === 1 ? 'Log In' : 'Register'}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

type SocialButtonProps = {
  text: string
  icon?: string
  className?: string
  onClick?: () => void
}

const SocialButton = (props: SocialButtonProps) => {
  const { icon, text, className = '', onClick } = props
  return (
    <Button onClick={onClick} className={className}>
      {icon && <Image src={icon} width={30} height={20} alt={text} />}
      <div className="flex-1 flex justify-center">{text}</div>
    </Button>
  )
}

export default AuthTab
