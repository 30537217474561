export const SESSION_TOKEN_KEY = 'session_token'
export const IN_BROWSER = (typeof window !== 'undefined')
export const setSessionToken = (token: string) => {
  if (!IN_BROWSER){
    return null;
  }
  localStorage.setItem(SESSION_TOKEN_KEY, token)
  return fetch('/api/session', {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({ token }),
  })
}

export const getSessionToken = (): string | null => {
  return IN_BROWSER ? localStorage.getItem(SESSION_TOKEN_KEY) : null;
}

export const removeSessionToken = () => {
  if (!IN_BROWSER){
    return null;
  }
  localStorage.removeItem(SESSION_TOKEN_KEY)
  return fetch('/api/session', {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({ token: '' }),
  })
}

