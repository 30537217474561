'use client'
import { showError } from '@licommon/utils/alert'
import { clsx } from '@licommon/utils/clsx'
import { getSessionToken } from '@licommon/utils/session'
import { fetchAndSetToken } from '@licommon/utils/token'
import { signIn, useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { getPortalType } from '../../utils/portal'
import Button from '../Button'
import TextInput from '../input/TextInput'
import OrComponent from '../OrComponent'

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup
      .string()
      .required()
      .min(8, 'Password must contain at least 8 characters'),
    remember: yup.bool(),
  })
  .required()

type LoginFormT = yup.InferType<typeof schema>

const LoginForm = ({
  onSuccess,
  initialData = {},
  showOtherOptions = false,
}: {
  initialData?: Partial<LoginFormT>
  onSuccess: () => void
  showOtherOptions?: boolean
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LoginFormT>()
  const [loading, setLoading] = useState(false)
  const { status } = useSession()
  useEffect(() => {
    Object.keys(initialData).forEach((key) => {
      //@ts-ignore
      setValue(key, initialData[key])
    })
  }, [initialData])
  const { isLI, isCFD } = getPortalType()

  const onSubmit: SubmitHandler<LoginFormT> = async (body) => {
    setLoading(true)
    const res = await signIn('credentials', {
      ...body,
      siteId: process.env.NEXT_PUBLIC_SITE_ID,
      session_token: getSessionToken(),
      redirect: false,
    })
    //@ts-ignore
    if (res.error) {
      showError('Invalid credentials, please try again.')
      setLoading(false)
      return
    }
    localStorage.removeItem('checkout')
    setLoading(false)
    fetchAndSetToken()
    onSuccess()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showOtherOptions && (
        <>
          <SmallSocialAuthIcons />
          <OrComponent />
        </>
      )}
      <TextInput
        required
        type="email"
        label="Email"
        register={register}
        name="email"
        id="login-email"
      ></TextInput>
      <TextInput
        required
        type="password"
        label="Password"
        register={register}
        name="password"
        id="login-password"
        error={errors.password?.message}
      ></TextInput>
      <div className="flex justify-between flex-wrap gap-4">
        <div>
          <input
            {...register('remember')}
            type="checkbox"
            id="remember-me"
          ></input>
          <label htmlFor="remember-me" className="select-none">
            Remember me
          </label>
        </div>
        <Link
          href="/auth/forgot-password"
          className={`underline text-xs ${
            isCFD ? 'text-blue-700' : 'text-green-700'
          } font-medium`}
        >
          Forgot password?
        </Link>
      </div>
      <Button
        loading={loading}
        disabled={status == 'authenticated'}
        variant={isCFD ? 'blue' : 'success'}
        className={clsx(
          'w-full mt-4 flex justify-center',
          isCFD && 'rounded-lg',
        )}
        type="submit"
      >
        Login
      </Button>
    </form>
  )
}

const ICON_SIZE = 28
const ICONS = [
  '/images/icons/icon-google.svg',
  '/images/icons/icon-apple.svg',
  '/images/icons/icon-facebook.svg',
]
export const SmallSocialAuthIcons = () => {
  return (
    <div className="flex gap-3 justify-evenly items-center pb-2">
      {ICONS.map((icon) => {
        return (
          <div
            key={icon}
            className="p-2 px-4 flex-1 flex justify-center border-2 rounded-md border-gray-300"
          >
            <Image
              height={ICON_SIZE}
              width={ICON_SIZE}
              alt="social auth icon"
              src={icon}
            />
          </div>
        )
      })}
    </div>
  )
}

export default LoginForm
