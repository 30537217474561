interface OrProps {
  title?: string;
}

const OrComponent = ({ title = "or" }: OrProps) => {
  return (
    <div className="flex items-center justify-center my-4">
      <div className="h-[1px] bg-gray-300 flex-1"></div>
      <div className="mx-4">{title}</div>
      <div className="h-[1px] bg-gray-300 flex-1"></div>
    </div>
  );
};

export default OrComponent;
