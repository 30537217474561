'use client'
import Link from 'next/link'
import {
  CSSProperties,
  LegacyRef,
  MouseEventHandler,
  ReactNode,
  Ref,
  forwardRef,
} from 'react'
import { clsx } from '../utils/clsx'
import LoadingSpinner from './LoadingSpinner'

type ButtonProps = {
  children: ReactNode
  className?: string
  variant?: 'success' | 'secondary' | 'danger' | 'warning' | 'blue'
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  href?: string
  flat?: boolean
  type?: 'submit' | 'reset' | 'button'
  loading?: boolean
  style?: CSSProperties
  target?: string
}
const Button = forwardRef((props: ButtonProps, ref) => {
  const {
    children,
    className = '',
    variant = 'secondary',
    disabled = false,
    onClick = (e) => {},
    href = null,
    flat = false,
    type = 'button',
    loading = false,
    style = {},
    target = '',
  } = props
  const site = process.env.NEXT_PUBLIC_SITE_ID == '2' ? 'cfd' : 'li'
  const cls = clsx(
    'py-2 px-4 flex items-center text-xs md:text-sm transition-all duration-200 ease-in-out font-medium select-none',
    variant == 'success' &&
      site == 'li' &&
      'bg-green-700 hover:bg-green-800 text-white',
    variant == 'success' &&
      site != 'li' &&
      'bg-blue-700 hover:bg-blue-800 text-white',
    variant == 'secondary' && 'text-gray-600 border-gray-300 hover:bg-gray-100',
    variant == 'danger' && 'bg-red-700 hover:bg-red-800 text-white',
    variant == 'warning' && 'bg-yellow-700 hover:bg-yellow-800 text-white',
    variant == 'blue' && 'bg-blue-700 text-white',
    !flat && 'rounded-full border shadow-sm',
    (loading || disabled) && 'opacity-50',
    className,
  )
  return !href ? (
    <button
      style={style}
      ref={ref as LegacyRef<HTMLButtonElement>}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={cls}
    >
      {loading && <LoadingSpinner className="mr-2 !h-4 !w-4"></LoadingSpinner>}
      {children}
    </button>
  ) : (
    <Link
      style={style}
      ref={ref as Ref<HTMLAnchorElement>}
      href={href}
      className={cls}
      target={target}
    >
      {children}
    </Link>
  )
})
Button.displayName = 'Button'
export default Button
