import { clsx } from '@licommon/utils/clsx'
import LoadingSpinner from './LoadingSpinner'

const SectionLoading = ({ className = '' }) => {
  return (
    <div
      className={clsx(
        'z-10 min-h-[50vh] w-full bg-white bg-opacity-80 left-0 top-0 flex items-center justify-center',
        className,
      )}
    >
      <LoadingSpinner />
    </div>
  )
}
export default SectionLoading
