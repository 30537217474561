import { clsx } from "@licommon/utils/clsx";
import {
    ChangeEventHandler,
    HTMLInputTypeAttribute,
    KeyboardEventHandler,
    ReactNode,
} from "react";
import {
    FieldValues,
    Path,
    RegisterOptions,
    UseFormRegister,
} from "react-hook-form";
import { getPortalType } from "../../utils/portal";
import { MagnifyingGlass } from "../Icons";

type TextInputProps<T extends FieldValues> = {
  label?: string | ReactNode;
  required?: boolean;
  placeholder?: string;
  error?: string | null;
  containerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  errorClassName?: string;
  id?: string;
  type?: Exclude<HTMLInputTypeAttribute, "radio" | "checkbox" | "date">;
  register?: UseFormRegister<T>;
  name?: Path<T>;
  validator?: RegisterOptions;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
  disabled?: boolean;
  optional?: boolean;
  autoComplete?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  searchIcon?: boolean;
};
const TextInput = <T extends FieldValues>(props: TextInputProps<T>) => {
  const {
    label,
    required = false,
    placeholder,
    error,
    containerClassName = "",
    inputClassName = "",
    labelClassName = "",
    errorClassName = "",
    id,
    type = "text",
    register,
    name,
    validator,
    onChange,
    value,
    optional = false,
    disabled = false,
    autoComplete,
    onKeyDown,
    searchIcon = false,
  } = props;

  const inputProps = {
    disabled,
    type,
    id,
    placeholder,
    onChange,
    name,
    required,
    autoComplete,
    onKeyDown,
    searchIcon,
    ...(register && name && register(name, validator)),
    className: clsx(inputClassName, error && "has-error"),
  };

  const {isCALIBO} = getPortalType()

  return (
    <div
      className={clsx(
        "relative flex flex-col mb-2 md:mb-4",
        containerClassName,
        isCALIBO && 'inner-field',
      )}
    >

      {searchIcon && (
          <MagnifyingGlass size={20} className="text-gray-500 z-10 ml-4 absolute top-1/2 transform -translate-y-1/2"/>
      )}
      {label && (
        <label htmlFor={id} className={clsx(labelClassName)}>
          {label}
          {optional && <span className="text-gray-400"> - Optional</span>}
        </label>
      )}
      {value !== undefined ? (
        <input {...inputProps} value={value || ""} />
      ) : (
        <input {...inputProps} />
      )}
      <InputError error={error} errorClassName={errorClassName} />
    </div>
  );
};
export const InputError = (props: {
  error?: string | null;
  errorClassName?: string;
}) => {
  const { error, errorClassName = "" } = props;
  if (!error) return null;
  return (
    <div
      className={clsx(
        "text-danger text-[0.72rem] mt-1 first-letter:capitalize",
        errorClassName,
      )}
    >
      {error}
    </div>
  );
};
export default TextInput;
