'use client'
import { yupResolver } from '@hookform/resolvers/yup'
import { alertSuccess, showError } from '@licommon/utils/alert'
import { clsx } from '@licommon/utils/clsx'
import { getSessionToken } from '@licommon/utils/session'
import { signIn } from 'next-auth/react'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import usePost from '../../hooks/usePost'
import { UserData } from '../../types/User'
import { getPortalType } from '../../utils/portal'
import Button from '../Button'
import OrComponent from '../OrComponent'
import Checkbox from '../input/Checkbox'
import TextInput from '../input/TextInput'
import { SmallSocialAuthIcons } from './LoginForm'

const schema = yup
  .object({
    name: yup
      .string()
      .required()
      .test('at-least-two-words', 'Please enter your full name', (value) => {
        if (!value) return false
        return value.trim().split(' ').length >= 2
      }),
    email: yup.string().email().required(),
    password: yup
      .string()
      .required()
      .min(8, 'Password must contain at least 8 characters')
      .max(20, 'Password must not contain more than 20 characters'),
    terms: yup.bool().oneOf([true], 'You must accept the terms and conditions'),
    disablePromotion: yup.bool(),
  })
  .required()

type RegisterFormT = yup.InferType<typeof schema>
type RegisterResponse = {
  success: boolean
  message: string
  authenticated_data?: string
  data?: UserData
}
const RegisterForm = ({
  onSuccess,
  initialData = {},
  showOtherOptions = false,
}: {
  initialData?: Partial<RegisterFormT>
  onSuccess: () => void
  showOtherOptions?: boolean
}) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      disablePromotion: false,
    },
    resolver: yupResolver(schema),
  })
  const { isCFD } = getPortalType()
  const { post: signup, loading } = usePost<RegisterFormT, RegisterResponse>(
    'api.register',
    setError,
  )
  useEffect(() => {
    Object.keys(initialData).forEach((key) => {
      //@ts-ignore
      setValue(key, initialData[key])
    })
  }, [initialData])

  const onSubmit: SubmitHandler<RegisterFormT> = async (body) => {
    try {
      const [firstName, lastName] = body.name.split(/ (.*)/s)
      const siteId = process.env.NEXT_PUBLIC_SITE_ID

      const response = await signup({
        ...body,
        firstName,
        lastName,
        siteId,
        session_token: getSessionToken(),
      })

      if (!response.success) {
        showError(response.message)
        return
      }

      await signIn('credentials', {
        email: body.email,
        password: body.password,
        session_token: getSessionToken(),
        siteId: siteId,
        redirect: false,
      })

      alertSuccess('Account created successfully.')
      onSuccess()
    } catch (err: any) {
      showError(err.message)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showOtherOptions && (
        <>
          <SmallSocialAuthIcons />
          <OrComponent />
        </>
      )}
      <TextInput
        required
        label="Name"
        register={register}
        name="name"
        error={errors.name?.message}
        id="register-name"
      ></TextInput>
      <TextInput
        required
        type="email"
        label="Email"
        register={register}
        name="email"
        error={errors.email?.message}
        id="register-email"
      ></TextInput>
      <TextInput
        required
        type="password"
        label="Password"
        register={register}
        name="password"
        error={errors.password?.message}
        id="register-password"
      ></TextInput>
      <Checkbox
        register={register}
        label="Please do not email me news and exclusive offers"
        id="disablePromotion"
        labelClassName="text-gray-800"
        name="disablePromotion"
      />
      <Button
        variant={isCFD ? 'blue' : 'success'}
        loading={loading}
        className={clsx(
          'w-full mt-4 mb-2 flex justify-center',
          isCFD && 'rounded-lg',
        )}
        type="submit"
      >
        Register
      </Button>
      <label
        htmlFor="terms-and-conditions"
        className="select-none inline-block"
      >
        I have read and understood the{' '}
        <a
          target="_blank"
          href="/terms-and-conditions"
          className={`underline ${isCFD ? 'text-blue-700' : 'text-green-700'}`}
        >
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a
          target="_blank"
          href="/privacy-policy"
          className={`underline ${isCFD ? 'text-blue-700' : 'text-green-700'}`}
        >
          Privacy Policy
        </a>
      </label>
    </form>
  )
}

export default RegisterForm
