import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const Toast = MySwal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', MySwal.stopTimer)
    toast.addEventListener('mouseleave', MySwal.resumeTimer)
  },
})

export const alertSuccess = (value: string) => {
  Toast.fire({
    icon: 'success',
    text: value,
  })
}

export const alertError = (value: string) => {
  Toast.fire({
    icon: 'error',
    text: value,
  })
}

export const showSuccess = (value: string) => {
  MySwal.fire({
    icon: 'success',
    text: value,
    showConfirmButton: false,
    timer: 1500,
    iconColor: '#71BF49',
  })
}

export const showError = (value: string) => {
  MySwal.fire({
    icon: 'error',
    text: value,
    showConfirmButton: false,
    timer: 3000,
    iconColor: '#fd3c22',
  })
}
