'use client'
import { Dialog, Transition } from '@headlessui/react'
import { clsx } from '@licommon/utils/clsx'
import { isEmpty } from 'lodash'
import { Fragment, ReactNode } from 'react'
import { X } from './Icons'

type ModalProps = {
  show: boolean
  onClose: () => void
  title: string | ReactNode
  subtitle?: string | ReactNode
  children: ReactNode
  containerClassName?: string
  titleClassName?: string
}

export default function Modal(props: ModalProps) {
  const {
    show,
    onClose,
    title,
    subtitle,
    children,
    containerClassName = '',
    titleClassName = '',
  } = props
  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-[100]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="flex min-h-full items-center justify-center p-1 md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={clsx(
                    'w-full p-4 max-w-2xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all',
                    containerClassName,
                  )}
                >
                  <Dialog.Title as="div">
                    <div className="flex justify-between text-gray-900 items-center first-letter:capitalize">
                      <h3 className={clsx('text-sm md:text-2xl font-medium', titleClassName)}>
                        {title}
                      </h3>
                      <X
                        className="cursor-pointer hidden md:block"
                        onClick={onClose}
                        size={20}
                        weight="bold"
                      />
                      <X
                        className="cursor-pointer block md:hidden"
                        onClick={onClose}
                        size={17}
                        weight="bold"
                      />
                    </div>
                    {subtitle && (
                      <h3 className="text-xs md:text-base mt-3">{subtitle}</h3>
                    )}
                  </Dialog.Title>
                  {!isEmpty(title) && (
                    <hr className="border-gray-300 my-2  md:mb-6" />
                  )}
                  <div className="overflow-y-auto max-h-[79vh] md:max-h-[78vh] px-[2px]">
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
